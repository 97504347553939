<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <article class="bg-white mh5-l mh0">
      <div class="ph5-l ph4 pv3">
        <div class="tc">
          <div class="ba b--light-gray bw2">
            <h1 class="tc f3 f2-ns ttu vidaloka">{{ $t("sign_in.title") }}</h1>
          </div>
        </div>
        <article class="karla tracked-light lh-copy">
          <note
            v-if="this.isNote"
            class="blue i f7 tc"
            :text="$t('sign_in.note')"
          />

          <p class="tc">{{ $t("sign_in.subtitle") }}</p>
          <signin class="measure center tl" />
        </article>
      </div>
    </article>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import signin from "../components/SignIn.vue";
import note from "../components/Note.vue";
export default {
  name: "sign-in-view",
  components: {
    signin,
    note
  },
  computed: {
    ...mapGetters("auth", {
      isNote: "isNote"
    })
  }
};
</script>

<style lang="stylus"></style>
