<template>
  <div class="auth-page">
    <form class="measure center tl">
      <div v-if="isAuthenticated">
        <button
          class="w-100 f6 dim dib ttu tracked b white bg-black link tc ph3 pv2 mb2"
          v-on:click.prevent="onSignOut()"
        >
          {{ $t("sign_in.sign_out") }}
        </button>
      </div>
      <div v-else>
        <fieldset class="ba b--transparent ph0 mh0">
          <div class="mt3">
            <!-- email -->
            <label class="db fw6 lh-copy f6" for="email">{{
              $t("sign_in.email")
            }}</label>
            <input
              name="email"
              id="email"
              class="pa2 input-reset ba bg-transparent w-100"
              type="email"
              v-model="email"
              placeholder
            />

            <!-- password -->
            <label class="db fw6 lh-copy f6" for="email">
              {{ $t("sign_in.password") }}
              <router-link
                class="ml1 f6 link underline fr"
                :to="$i18nRoute({ name: 'password_reset' })"
                >{{ $t("sign_in.forgot") }}</router-link
              >
            </label>
            <input
              name="password"
              id="password"
              class="pa2 input-reset ba bg-transparent w-100"
              type="password"
              v-model="password"
              placeholder
            />
          </div>
        </fieldset>

        <button
          class="w-100 f6 fw6 dim dib ttu tracked b white bg-black link tc ph3 pv2 mb2 lh-copy roboto sans-serif"
          :disabled="!email || !password"
          v-on:click.prevent="onSignIn(email, password)"
        >
          {{ $t("sign_in.sign_in") }}
        </button>
      </div>
    </form>

    <div v-if="loading" class="loading">Loading...</div>

    <div v-if="error" class="dark-red">{{ error }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "sign-in",
  data() {
    return {
      email: null,
      password: null
    };
  },
  methods: {
    onSignIn(email, password) {
      this.$store.dispatch("auth/signIn", {
        email: email,
        password: password,
        cookie: this.$cookie,
        router: this.$router
      });
    },
    onForgotPassword() {},
    onSignOut() {
      this.$store.dispatch("auth/signOut", { cookie: this.$cookie });
    }
  },
  computed: {
    ...mapState({
      error: state => state.auth.error,
      loading: state => state.auth.loading,
      isAuthenticated: state => state.auth.isAuthenticated
    })
  },
  created() {},
  mounted() {},
  watch: {
    // call again the method if the route changes
    $route: "isAuthenticated"
  }
};
</script>
