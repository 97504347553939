<template>
  <p>{{ text }}</p>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "note",
  props: {
    text: String
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  created() {},
  mounted() {},
  components: {}
};
</script>
